import React from 'react';

import Layout from '../components/layout/main';
import Head from '../components/layout/head';

const ThankYou = () => {
  return (
    <Layout>
      <Head title={'Thank you!'} />

      <div className="box-container mt-5 pb-5 px-3 px-md-5 text-left">
        <h3 className="fs-35 ganen-green ganen-bold mb-5">
          Thank you for your interest in GANEN!
        </h3>
        <p className="mb-3">
          Our Ganen Specialist will be in touch with you by the next business
          day regarding your request.
        </p>
        ​
        <p className="mb-5">
          Know someone who may be interested in customising their own smart home
          automation and security solution? Why not share their details with us
          and get{' '}
        </p>
        ​<p className="mb-1"> Sincerely,</p>
        <p className="mb-5">GANEN</p>
      </div>
    </Layout>
  );
};

export default ThankYou;
